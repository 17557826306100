
import {Component, Prop, Vue} from "vue-property-decorator";
import { AssistantListItem } from "../assistants/assistantsWebServices";
import { getNewestAssistants } from "../assistants/searchWebServices";
import { getBaseImagesUrl } from "../helpers/settingsHelper";
import { Carousel, Slide } from "vue-carousel";


@Component({
  components: {
    Carousel,
    Slide
  }
})
export default class AssistantSlider extends Vue {
    @Prop( { default: 'default' } ) type!: string;

    assistants: AssistantListItem[] = [];

    async mounted() {
        if (this.type === 'active') {
            try {
              const { data } = await getNewestAssistants()

              this.assistants = data;
            } catch (e) {}

            return
        }
        try {
            const { data } = await getNewestAssistants()

            this.assistants = data;
        }
        catch (e) {}
    }

    get imagesUrl() {
       return getBaseImagesUrl();
    }

    get perPageCustom() {
        return [
            [480, 3], 
            [600, 4], 
            [750, 5], 
            [800, 6], 
            [920, 7], 
            [1050, 8], 
            [1170, 9], 
            [1300, 10],
            [1440, 11],
            [1570, 12],
            [1700, 13],
            [1850, 14],
            [1990, 15],
            [2150, 16],
            [2250, 17],
        ];
    }
}
