
import { Component, Vue } from "vue-property-decorator";
import store from "../store";
import { AccountType } from "../register/registerModel";
import NotLoggedLandingPage from "./NotLoggedLandingPage.vue";
import AssistantLandingPage from "./AssistantLandingPage.vue";
import ClientLandingPage from "./ClientLandingPage.vue";


@Component({
  components: {
    NotLoggedLandingPage,
    AssistantLandingPage,
    ClientLandingPage
  }
})
export default class HomePictures extends Vue {
  

  get isLoggedIn() {
    return !!store.getters.module.user;
  }

  get isLoggedInAsAssistant() {
    return store.getters.module.user?.accountType === AccountType.Assistant;
  }

  get isLoggedInAsClient() {
    return store.getters.module.user?.accountType === AccountType.Client;
  }
}
