
import { Component, Vue } from "vue-property-decorator";
import { AssistantListItem } from "@/assistants/assistantsWebServices";
import { getNewestAssistants } from "@/assistants/searchWebServices";
import Expert from "@/home/Expert.vue";
import {fetchPage, Page} from "@/serviceClients/pageWebServices";
import store from "@/store";

@Component({
    components: {
        Expert
    }
})
export default class Experts extends Vue {
    view: Page = {} as Page;
    assistants: AssistantListItem[] = [];

    mounted() {
        this.fetchUsers();
        this.fetchData();
    }

    fetchUsers() {
        getNewestAssistants()
            .then(( response ) => {
                this.assistants = response.data;
            }).catch(() => {
            /* ignore the error and show nothing. Loading is also not neccessery, better show nothing */
        });
    }

    fetchData() {
        fetchPage("HomepgaeExpertsTop")
            .then((resp) => {
              this.view = resp.data;

              store.commit.module.setActiveModuleName(this.view.title);
            })
            .finally(() => {
              // this.isLoading = false;
            })
            .catch((error) => {
              // do nothing I can - remain silent must I
            });
    }
}
