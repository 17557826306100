
import HomePictures from "@/home/HomePictures.vue";
import InformationWithIcons from "@/home/InformationWithIcons.vue";
import FindPerson from "@/home/FindPerson.vue";
import Benefits from "@/home/Benefits.vue";
import JoinUs from "@/home/JoinUs.vue";
import Experts from "@/home/Experts.vue";
import Guide from "@/home/Guide.vue";
import AssistantCategoriesList from "@/assistants/AssistantCategoriesList.vue";
import { Component, Vue } from "vue-property-decorator";
import store from "@/store";
import { AccountType } from "../register/registerModel";
import AssistantSlider from "@/home/AssistantSlider.vue";
import About from "@/home/About.vue";
import PopularCategories from "@/home/PopularCategories.vue";

@Component({
  components: {
    HomePictures,
    AssistantCategoriesList,
    InformationWithIcons,
    FindPerson,
    Benefits,
    JoinUs,
    Experts,
    Guide,
    AssistantSlider,
    About,
    PopularCategories
  },
})
export default class Home extends Vue {
  mounted() {
  }

  get isUserAssistant() {
    return store.getters.module.user?.accountType === AccountType.Assistant;
  }
}
