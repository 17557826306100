
import { Component, Vue } from "vue-property-decorator";

@Component
export default class PopularCategories extends Vue {
    allVisible = false

    clickSeeAll() {
        this.allVisible = true
    }

    scrollToCategoriesList () {
        const categoriesList = document.querySelector('#popularCategories') as HTMLElement;

        if (categoriesList) {
            categoriesList.scrollIntoView({ behavior: 'smooth' });
        }
    }
}
