
import { Component, Vue } from "vue-property-decorator";

@Component
export default class About extends Vue {
  get videoUrlFindDreamJob(): string {
    switch (this.$i18n.locale) {
      case 'pl':
        return 'https://www.youtube.com/embed/rJ_Qu70x72w'
      default:
        return 'https://www.youtube.com/embed/EdLzWzKFUes';
    }
  }

  get videoUrlClient(): string {
    switch (this.$i18n.locale) {
      case 'pl':
        return 'https://www.youtube.com/embed/PrSEOsb0uUE'
      default:
        return 'https://www.youtube.com/embed/QJjNOF7h9Ho';
    }
  }
}
