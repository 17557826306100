
import { Component, Vue } from "vue-property-decorator";
import {fetchPage, Page} from "@/serviceClients/pageWebServices";
import store from "@/store";

@Component
export default class FindPerson extends Vue {
  view: Page = {} as Page;

  mounted() {
    fetchPage("HomepageFindPerson")
      .then((resp) => {
        this.view = resp.data;

        store.commit.module.setActiveModuleName(this.view.title);
      })
      .finally(() => {
        // this.isLoading = false;
      })
      .catch((error) => {
        // do nothing I can - remain silent must I
      });
  }
}
