
import { Component, Vue } from "vue-property-decorator";
import { getLanguage } from "@/core/translate";

@Component
export default class NotLoggedLandingPage extends Vue {

  get polishLanguage (): boolean {
    return getLanguage() === 'pl'
    }
}
