
import {Component, Prop, Vue} from "vue-property-decorator";
import GuideItem from "@/components/GuideItem.vue";

@Component({
  components: {
    GuideItem
  }
})
export default class Guide extends Vue {
  @Prop({required: false}) title!: string
}
