
import { getPagePhotosUrl } from "@/helpers/settingsHelper";
import { Component, Vue } from "vue-property-decorator";
import { fetchPage, Page } from "../serviceClients/pageWebServices";
import Invite from "../views/Invite.vue";

@Component({
  components: {
    Invite
  }
})
export default class AssistantLandingPage extends Vue {
  page: Page = {} as Page;

  get pageText() {
    return this.page?.text;
  }

  get pageTitle() {
    return this.page?.title;
  }

  get pagePhotoUrl() {
    return getPagePhotosUrl();
  }

  mounted() {
    this.page = {} as Page;
    fetchPage("AssistantLandingPage")
      .then((resp) => {
        this.page = resp.data;
      })
      .catch(() => { /* no-op */});
  }

}
