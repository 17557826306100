
import { Component, Prop, Vue } from "vue-property-decorator";
import { getCategoriesImagesUrl } from "../helpers/settingsHelper";
import {
  fetchPageCategories,
  PageCategoryListModel,
} from "../serviceClients/categoryWebServices";
import { pushError } from "@/core";


@Component
export default class AssistantCategoriesList extends Vue {
    @Prop() shownByDefault?: boolean;

    model: PageCategoryListModel = {
        onlineGroups: [],
        offlineGroups: []
    } as PageCategoryListModel;
    isLoading = true;
    activeBox = 0

    get groupList() {
        if (this.activeBox === 0) {
            return this.model.onlineGroups.sort((a, b) => a.order - b.order);
        }

        if (this.activeBox === 1) {
            return this.model.offlineGroups.sort((a, b) => a.order - b.order);
        }

        return [];
    }

    get imagesUrl() {
        return getCategoriesImagesUrl();
    }

    toggleActiveBox (val: number): void {
        this.activeBox = val
    }

    isActiveBox (val: number): boolean {
        return this.activeBox === val
    }

    async mounted() {
        if (this.shownByDefault) {
            this.toggleActiveBox(0);
        }

        await this.fetchData()
    }

    async fetchData() {
        try {
            const { data } = await fetchPageCategories()

            this.model = data;
        } catch (error) {
            pushError(error.response?.data?.error || error, {title: this.$t("An error occurred while fetching data")});
        } finally {
            this.isLoading = false;
        }
    }
}
