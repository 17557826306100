
import { Component, Prop, Vue } from "vue-property-decorator";
import { AssistantListItem } from "../assistants/assistantsWebServices";
import { getBaseImagesUrl } from "../helpers/settingsHelper";

@Component
export default class Expert extends Vue {
    @Prop({ default: null, required: true }) assistant!:AssistantListItem

    get imagesUrl(): string | undefined {
        return getBaseImagesUrl();
    }
}
